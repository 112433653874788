/* eslint-disable max-len */
/**
 * Calculates a route between two locations using the TomTom
 * [Routing API](CALCULATE_ROUTE_URL);
 *
 * Parameters need to be passed to the constructor.
 *
 * ### The types used in the Routing service:
 *  - point - longitude, latitude pair (in EPSG4326 projection), with the following constraints:
 *    - Longitude values must be in the range -180..+180 inclusive.
 *    - Latitude values must be in the range -90..+90 inclusive.
 *    - Example: 4.89406, 52.37245
 *
 *  - circle - A circle with a center point and a radius (in meters).
 *    - The radius must be a positive integer with the maximum value of 20050000.
 *    - Note that larger integer values will not be rejected but lowered to the maximum value.
 *    - Example: circle(4.89406,52.37245,10000)
 *
 *  - location - A point or a circle.
 *    - Example: 4.89406,52.37245  circle(4.89406,52.37245,10000)
 *
 *  - dateTime - A date and time specified in RFC-3339 format with an optional time zone offset.
 *    - Example: 1996-12-19T16:39:57  1996-12-19T16:39:57-08:00
 *
 * ### Response
 * This service extends the API response by providing the following methods:
 * * `toGeoJson()`, converts route data into a
 * FeatureCollection of <a target="_blank" rel=”noopener” href="https://tools.ietf.org/html/rfc7946#section-3.1.4">LineString</a>
 * or <a target="_blank" rel=”noopener” href="https://tools.ietf.org/html/rfc7946#section-3.1.5">MultiLineString</a> geometries.
 * * `toRouteSectionsCollection()`, provides an array of FeatureCollections. Each FeatureCollection consists of
 * <a target="_blank" rel=”noopener” href="https://tools.ietf.org/html/rfc7946#section-3.1.4">LineStrings </a> representing segments
 * of the route. Take a look at
 * <a target="_blank"  rel=”noopener”
 * href="https://developer.tomtom.com/maps-sdk-web-js/functional-examples#examples,code,route-sections.html">
 * Route sections example</a> to see it in action.
 * * `getTrackingId()`, returns the `Tracking-ID` associated with the request.
 *
 * To read more about services responses take a look at {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 * or dive into <a target="_blank"  rel=”noopener”
 * href="https://developer.tomtom.com/maps-sdk-web-js/functional-examples#examples,code,static-route.html">
 * Static route example</a> code.
 *
 * @class calculateRoute
 * @namespace Services.services
 * @module Services
 * @uses KeyMixin
 * @uses CommonRoutingParameters
 * @uses RoutingParameters
 * @uses BatchMixin
 * @uses TrackingIdMixin
 * @uses ReturnsToGeoJsonMixin
 * @uses AbortSignalMixin
 * @constructor
 * @param {Object|Array} [options] Options to be passed to the routing call,
 *     or an array of such options objects to perform a batch request.
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 *
 * @example
 * ```js
 * tt.services.calculateRoute({
 *   key: <Your API key>,
 *   locations: '4.8,52.3:4.87,52.37'
 * }).then(function(routeData) {
 *     console.log(routeData.toGeoJson());
 *   });
 * ```
 */
/* eslint-enable max-len */
/* jshint nomen:false */
import {SERVICE_TYPES} from 'Core/serviceTypes';
import calculateRouteModel from '../../model/routing/calculateRoute';
import batchCalculateRouteModel from '../../model/routing/batchCalculateRoute';
import getRoutingParameters from './routingParameters';
import modelResponse from '../../model/modelResponse';
import {
    routingEndpoint,
    batchSyncRoutingEndpoint,
    batchRoutingEndpoint,
    batchRoutingQueryEndpoint
} from 'endpoints';

const fields = getRoutingParameters();
const maxNoOfElementsInBatchRequest = 100;

export function calculateRoute(core) {
    const singleRequest = core.singleRequestServiceFactory(routingEndpoint);
    const batchRequest = core.batchRequestServiceFactory(maxNoOfElementsInBatchRequest, {
        batchSync: batchSyncRoutingEndpoint,
        batch: batchRoutingEndpoint,
        single: batchRoutingQueryEndpoint
    });

    function handleServiceCall(data, abortSignal) {
        return singleRequest(fields, data, abortSignal)
            .then(response => modelResponse(response, calculateRouteModel));
    }
    function handleBatchServiceCall(data, abortSignal) {
        return batchRequest(fields, data, abortSignal)
            .then(response => modelResponse(response, batchCalculateRouteModel));
    }

    return core.serviceFactory(
        fields, SERVICE_TYPES.ROUTING, 'calculateRoute', handleServiceCall, handleBatchServiceCall
    );
}
