/**
 * The copyright caption service implementation provides a caption that is supposed to be displayed on a link
 * that provides the full copyright notice. The response is not changed often.
 *
 * This service is supposed to be called once when user has the map displayed for the first time.
 *
 * ### Response
 * The response from the service is an object containing "copyrightsCaption", e.g.:
 * ```
 * {
 *   "formatVersion": "0.0.1",
 *   "copyrightsCaption": "© 1992 - 2020 TomTom."
 * }
 *```
 *
 * The response is also extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * This text ("© 1992 - 2020 TomTom.") has to be displayed as a copyrights prompt when using TomTom services.
 * The caption should be clickable. User should be presented with the full copyrights notice when
 * the caption is clicked. Full copyright notice can be retrieved from the
 * {{#crossLink "Services.services.copyrights"}}Copyrights service{{/crossLink}}.
 *
 * @example
 * ```js
 * tt.services.copyrightsCaption({
 *   key: <Your API key>
*  }).then(function(response) {
 *   console.log(response);
 * });
 * ```
 *
 * @class copyrightsCaption
 * @namespace Services.services
 * @module Services
 * @uses TrackingIdMixin
 * @constructor
 * @uses KeyMixin
 * @uses ProtocolMixin
 * @uses AbortSignalMixin
 *
 * @param {Object} [options]
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 */
import {SERVICE_TYPES} from 'Core/serviceTypes';
import parameterApplications from '../../common/parameterApplications';
import modelResponse from '../../model/modelResponse';
import uuid from 'uuid/v4';
import * as validators from '../validators';
import {
    captionEndpoint
} from 'endpoints';
const fields = {
    key: {
        validators: [validators.key]
    },
    trackingId: {
        validators: [validators.trackingId],
        application: parameterApplications.HEADER,
        defaultValue: uuid
    },
    protocol: {
        validators: [validators.oneOfValue(['http', 'https'], 'protocol')]
    },
    sessionId: {
        validators: [validators.string]
    }
};

export function copyrightsCaption(core) {
    const singleRequest = core.singleRequestServiceFactory(captionEndpoint);
    function handleServiceCall(data, abortSignal) {
        return singleRequest(fields, data, abortSignal).then(modelResponse);
    }
    return core.serviceFactory(fields, SERVICE_TYPES.MAP, 'copyrightsCaption', handleServiceCall);

}
