/* eslint-disable object-curly-spacing */
/* eslint-disable no-undef */
/**
 * Retrieve polygons (in GeoJSON format) representing administrative borders using IDs related to
 * search results entities.
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 * The response is extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * tt.services.fuzzySearch({
 *      key: <Your API key>,
 *      query: "Amsterdam"
 * }).then(function(response) {
 *      var geometryId = response.results[0].dataSources.geometry.id;
 *      tt.services.additionalData({
 *          key: <Your API key>,
 *          geometries: [geometryId]
 *      }).then(function(adpResponse) {
 *          console.log(adpResponse);
 *      })
 *   });
 * ```
 *
 * @class additionalData
 * @module Services
 * @namespace Services.services
 * @uses KeyMixin
 * @uses BatchMixin
 * @uses GeometriesMixin
 * @uses GeometriesZoomMixin
 * @uses TrackingIdMixin
 * @uses AbortSignalMixin
 * @constructor
 *
 * @param {Object} [options]
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 */

import {SERVICE_TYPES} from 'Core/serviceTypes';
import * as validators from '../validators';
import batchModel from '../../model/search/batchModel';
import parameterApplications from '../../common/parameterApplications';
import modelResponse from '../../model/modelResponse';
import uuid from 'uuid/v4';
import {
    adpEndpoint,
    batchAdpQueryEndpoint,
    batchSyncSearchEndpoint,
    batchSearchEndpoint
} from 'endpoints';

const fields = {
    key: {
        validators: [validators.string]
    },
    trackingId: {
        validators: [validators.trackingId],
        application: parameterApplications.HEADER,
        defaultValue: uuid
    },
    geometries: {
        validators: [validators.arrayType],
        required: true
    },
    geometriesZoom: {
        validators: [validators.geometriesZoom]
    }
};

export function additionalData(core) {
    const singleAdpRequest = core.singleRequestServiceFactory(adpEndpoint);
    const batchAdpRequest = core.batchRequestServiceFactory(100, {
        single: batchAdpQueryEndpoint,
        batchSync: batchSyncSearchEndpoint,
        batch: batchSearchEndpoint
    });

    function handleServiceCall(data, abortSignal) {
        return singleAdpRequest(fields, data, abortSignal).then(modelResponse);
    }
    function handleBatchServiceCall(data, abortSignal) {
        return batchAdpRequest(fields, data, abortSignal).then(response => modelResponse(response, batchModel));
    }

    return core.serviceFactory(
        fields,
        SERVICE_TYPES.SEARCH,
        'additionalData',
        handleServiceCall,
        handleBatchServiceCall
    );
}
