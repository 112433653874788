import { SERVICE_TYPES } from 'Core/serviceTypes';
import parameterApplications from '../../common/parameterApplications';
import modelResponse from '../../model/modelResponse';
import uuid from 'uuid/v4';
import * as validators from '../validators';
import { poiDetailsEndpoint } from 'endpoints';

/**
 * Makes a poi details request using the TomTom [POI Details API](POI_DETAILS_URL).
 *
 * This service provides additional information about POIs including:
 * * rating
 * * price range
 * * photo IDs
 * * user reviews
 *
 * This service has **specific terms and conditions of usage**. For more information about this service please
 * refer to the [POI Details API documentation](POI_DETAILS_URL).
 *
 * ### Response
 * The response is extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @class poiDetails
 * @module Services
 * @namespace Services.services
 * @uses KeyMixin
 * @uses TrackingIdMixin
 * @uses AbortSignalMixin
 * @constructor
 *
 * @param {Object} [options] Options to be passed to the call.
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 */
const fields = {
    key: {
        validators: [validators.string],
        required: true
    },
    trackingId: {
        validators: [validators.trackingId],
        application: parameterApplications.HEADER,
        defaultValue: uuid
    },
    /**
     * @attribute id
     * @param {String} [options.id] POI details id which is previously retrieved from a Search request.
     */
    id: {
        validators: [validators.string],
        required: true
    }
};

export function poiDetails(core) {
    const singleRequest = core.singleRequestServiceFactory(poiDetailsEndpoint);

    function handleServiceCall(data, abortSignal) {
        return singleRequest(fields, data, abortSignal).then(modelResponse);
    }

    return core.serviceFactory(fields, SERVICE_TYPES.SEARCH, 'poiDetails', handleServiceCall);
}
