/**
 * Makes a search request using the TomTom
 * [Search API - Fuzzy Search](FUZZY_SEARCH_URL).
 *
 * Parameters need to be be passed to the constructor.
 *
 *
 * The list of all supported category codes are available
 * [here](SEARCH_API_SUPPORTED_CATEGORY_CODES_URL).
 *
 * ### Response
 * The response is extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * function callbackFn(result) {
 *   console.log(result);
 * };
 *
 * tt.services.fuzzySearch({
 *   key: <Your API key>,
 *   query: 'Amsterdam'
 * }).then(callbackFn);
 * ```
 *
 * @class fuzzySearch
 * @namespace Services.services
 * @extends Search
 * @uses ExtendedPostalCodesForMixin
 * @uses CenterMixin
 * @uses RadiusMixin
 * @uses BoundingBoxMixin
 * @uses CountrySetMixin
 * @uses OffsetMixin
 * @uses BestResultMixin
 * @uses QueryMixin
 * @uses TypeaheadMixin
 * @uses MaxFuzzyLevel
 * @uses MinFuzzyLevel
 * @uses IdxSetMixin
 * @uses EntityTypeSetMixin
 * @uses CategorySetMixin
 * @uses ViewMixin
 * @uses BrandSetMixin
 * @uses ConnectorSetMixin
 * @uses OpeningHoursMixin
 * @uses MapcodesMixin
 * @uses FuelSetMixin
 * @uses MinPowerKwMixin
 * @uses MaxPowerKwMixin
 * @uses AbortSignalMixin
 * @constructor
 * @module Services
 *
 * @param {Object} [options] Options to be passed to the search call,
 *     or an array of such options objects to perform a batch request.
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 */

import * as validators from '../validators';
import converters from '../converters';
import Search from './search';
import { boundingBox } from './searchParameters';

class FuzzySearch extends Search {
    constructor(options, additionalOptions) {
        super(options, additionalOptions);

        this.fields.minFuzzyLevel = {
            validators: [validators.fuzzinessLevel],
            defaultValue: 1
        };

        this.fields.maxFuzzyLevel = {
            validators: [validators.fuzzinessLevel],
            defaultValue: 2
        };

        this.fields.idxSet = {
            validators: [validators.string]
        };

        this.fields.entityTypeSet = {
            converters: [converters.array, converters.commaSeparated],
            validators: [validators.entityType]
        };

        this.fields.sessionId = {
            validators: [validators.string]
        };

        this.fields.clientTime = {
            validators: [validators.clientTime]
        };

        this.fields.boundingBox = boundingBox();
    }
}

export function fuzzySearch(core) {
    return (options, additionalOptions) => (new FuzzySearch(options, additionalOptions)).construct('fuzzySearch', core);
}
