import * as validators from '../validators';
import converters from '../converters';
import types from './types';
import parameterApplications from '../../common/parameterApplications';
import uuid from 'uuid/v4';

const geocodeBaseFields = {
    key: {
        validators: [validators.string]
    },
    trackingId: {
        validators: [validators.trackingId],
        application: parameterApplications.HEADER,
        defaultValue: uuid
    },
    language: {
        validators: [validators.languageCode]
    },
    position: {
        converters: [converters.point],
        validators: [validators.point],
        required: true,
        application: parameterApplications.PATH
    },
    heading: {
        validators: [validators.number]
    },
    radius: {
        validators: [validators.naturalInteger]
    },
    protocol: {
        validators: [validators.oneOfValue(['http', 'https'], 'protocol')]
    },
    view: {
        validators: [validators.oneOfValue(['AR', 'IL', 'MA', 'IN', 'PK', 'Unified', 'RU', 'TR', 'CN'], 'view')]
    },
    allowFreeformNewline: {
        validators: [validators.bool]
    }
};

const additionalGeocodeFields = {
    type: {
        defaultValue: types.reverseGeocode,
        visible: false,
        application: parameterApplications.PATH
    },

    entityType: {
        converters: [converters.array, converters.commaSeparated],
        validators: [validators.entityType]
    },

    returnSpeedLimit: {
        validators: [validators.bool]
    },

    number: {
        validators: [validators.string]
    },

    returnRoadUse: {
        validators: [validators.bool]
    },

    roadUse: {
        converters: [converters.roadUse],
        validators: [validators.roadUse]
    },

    streetNumber: {
        validators: [validators.string]
    },

    returnMatchType: {
        validators: [validators.bool]
    },

    mapcodes: {
        validators: [validators.mapcodes]
    },

    dateTime: {
        validators: [validators.dateTime]
    }
};

const crossStreetLookupFields = {
    type: {
        defaultValue: types.crossStreetLookup,
        visible: false,
        application: parameterApplications.PATH
    },

    limit: {
        validators: [validators.limit]
    }
};

export default (type) => {
    if (type === types.reverseGeocode) {
        return Object.assign({}, geocodeBaseFields, additionalGeocodeFields);
    } else if (type === types.crossStreetLookup) {
        return Object.assign({}, geocodeBaseFields, crossStreetLookupFields);
    }
    throw new Error('Unsupported geocode type: ' + type);
};
