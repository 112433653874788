/**
 * Get the charging connector data consisting of type and numbers of the existing charging stations,
 * based on EV station ID retrieved from a FuzzySearch request called with connectorSet parameter.
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 * The response is extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 *
 * function callbackFn(results) {
 *   console.log(results);
 * }
 *
 * tt.services.fuzzySearch({
 *      key: <Your API key>,
 *      query: 'station',
 *      connectorSet: 'Tesla'
 * }).then(function(response) {
 *      var chargingStationID = response.results[0].dataSources.chargingAvailability.id;
 *      tt.services.evChargingStationsAvailability({
 *          key: <Your API key>,
 *          chargingAvailability: chargingStationID
 *      }).then(callbackFn)
 *   });
 * ```
 *
 * @class evChargingStationsAvailability
 * @namespace Services.services
 * @uses KeyMixin
 * @uses BatchMixin
 * @uses ConnectorSetMixin
 * @uses MinPowerKwMixin
 * @uses MaxPowerKwMixin
 * @uses chargingAvailabilityMixin
 * @uses TrackingIdMixin
 * @uses AbortSignalMixin
 *
 * @constructor
 * @module Services
 *
 * @param {Object} [options]
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 */

import { SERVICE_TYPES } from 'Core/serviceTypes';
import * as validators from '../validators';
import batchModel from '../../model/search/batchModel';
import parameterApplications from '../../common/parameterApplications';
import modelResponse from '../../model/modelResponse';
import uuid from 'uuid/v4';
import {
    chargingAvailabilityEndpoint,
    batchChargingAvailabilityQueryEndpoint,
    batchSyncSearchEndpoint,
    batchSearchEndpoint
} from 'endpoints';

const fields = {
    key: {
        validators: [validators.key]
    },

    chargingAvailability: {
        validators: [validators.string],
        required: true
    },

    trackingId: {
        validators: [validators.trackingId],
        application: parameterApplications.HEADER,
        defaultValue: uuid
    },
    connectorSet: {
        validators: [validators.connectorSet]
    },

    minPowerKW: {
        validators: [validators.number]
    },

    maxPowerKW: {
        validators: [validators.number]
    }
};

export function evChargingStationsAvailability(core) {
    const singleRequest = core.singleRequestServiceFactory(chargingAvailabilityEndpoint);
    const batchRequest = core.batchRequestServiceFactory(100, {
        single: batchChargingAvailabilityQueryEndpoint,
        batchSync: batchSyncSearchEndpoint,
        batch: batchSearchEndpoint
    });

    function handleServiceCall(data, abortSignal) {
        return singleRequest(fields, data, abortSignal).then(modelResponse);
    }
    function handleBatchServiceCall(data, abortSignal) {
        return batchRequest(fields, data, abortSignal).then(response => modelResponse(response, batchModel));
    }

    return core.serviceFactory(
        fields,
        SERVICE_TYPES.EXTENDED_SEARCH,
        'chargingAvailability',
        handleServiceCall,
        handleBatchServiceCall
    );
}
