import * as validators from '../validators';
import converters from '../converters';
import parameterApplications from '../../common/parameterApplications';
import uuid from 'uuid/v4';

export default {
    create: () => {
        return {
            key: {
                validators: [validators.string]
            },
            trackingId: {
                validators: [validators.trackingId],
                application: parameterApplications.HEADER,
                defaultValue: uuid
            },
            boundingBox: {
                validators: [validators.boundingBox],
                converters: [converters.boundingBox],
                application: parameterApplications.PATH,
                cast: (boundingBox, requestOptions) => {
                    requestOptions.minLon = boundingBox.minLon;
                    requestOptions.maxLon = boundingBox.maxLon;
                    requestOptions.minLat = boundingBox.minLat;
                    requestOptions.maxLat = boundingBox.maxLat;
                }
            },
            zoom: {
                validators: [validators.zoomLevel],
                converters: [converters.integer],
                application: parameterApplications.PATH
            },
            x: {
                validators: [validators.integer],
                converters: [converters.integer],
                application: parameterApplications.PATH
            },
            y: {
                validators: [validators.integer],
                converters: [converters.integer],
                application: parameterApplications.PATH
            },
            protocol: {
                validators: [validators.oneOfValue(['http', 'https'], 'protocol')]
            },
            sessionId: {
                validators: [validators.string]
            }
        };
    }
};
